<template>
  <div>
    <div class="uk-width-1-1" v-if="!editColumn">
      <div class="uk-background-default uk-padding-small">
        <form class="uk-form-stacked">
          <div class="uk-margin">
            <label class="uk-form-label">Layout</label>
            <div @click.prevent="editGrid">
              <img alt :src="`/img/builder/grid/${layoutImage}`" data-uk-svg />
              <p class="uk-margin-small uk-margin-remove-bottom">{{ layoutLabel }}</p>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Spalten</label>
            <div
              v-for="(column, index) in localData.child"
              :key="index"
              class="uk-margin-small">
                <button type="button" class="uk-button uk-flex uk-flex-middle uk-background-muted uk-text-left" @click.prevent="edit(column)">
                  Spalte {{ index +1 }} bearbeiten
                  <span data-uk-icon="chevron-right"></span>
                </button>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Abstand</label>
            <select class="uk-select" v-model="form.gutter">
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value>Default</option>
              <option value="large">Large</option>
              <option value="collapse">None</option>
            </select>
          </div>
          <div class="uk-margin">
             <label>
               <input class="uk-checkbox" type="checkbox" v-model="form.height" :true-value="'match'"> Gleiche Höhe für alle Blöcke
            </label>
          </div>
        </form>
      </div>
      <modal ref="selectRowModal" :large="true">
        <div class="uk-modal-header">
          <h3>Grid Layout wählen</h3>
        </div>
        <div class="uk-modal-body uk-grid-collapse uk-child-width-1-4 uk-grid" data-uk-grid>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Ganze Breite" src="/img/builder/grid/whole.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Ganze Breite</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid('1-1')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Hälften" src="/img/builder/grid/halves.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Hälften</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid(',')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Drittel" src="/img/builder/grid/thirds.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Drittel</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid('1-3,')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Viertel" src="/img/builder/grid/quarters.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Viertel</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid('1-4,')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Drittel 2-1" src="/img/builder/grid/thirds-2-1.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Drittel 2-1</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid('2-3,')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Drittel 1-2" src="/img/builder/grid/thirds-1-2.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Drittel 1-2</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid(',2-3')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Viertel 3-1" src="/img/builder/grid/quarters-3-1.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Viertel 3-1</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid('3-4,')"></a>
          </div>
          <div class="uk-card uk-card-body uk-card-hover uk-padding-remove-horizontal uk-text-center">
            <img alt="Viertel 1-3" src="/img/builder/grid/quarters-1-3.svg" data-uk-svg />
            <p class="uk-margin-small-top uk-margin-remove-bottom uk-text-truncate">Viertel 1-3</p>
            <a href="#" class="uk-position-cover" @click.prevent="selectGrid(',3-4')"></a>
          </div>
        </div>
      </modal>
    </div>
    <column-edit v-else :data="this.editColumnData" />
  </div>

</template>

<script>
import vSelect from "vue-select";
import Modal from "@/components/Modal";
import ColumnEdit from './ColumnEdit'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    vSelect,
    Modal,
    ColumnEdit
  },
  data() {
    return {
      form: {},
      localData: {},
      layoutLabel: 'Ganze Breite',
      defaultColumnData: {
        child: [],
        props: {
          image_position: "center-center",
          media_overlay_gradient: "",
          padding: "",
          style: "",
          text_color: "",
          vertical_align: "",
          widths: ["expand"]
        },
        type: "column"
      },
      editColumn: false,
      editColumnData: null
    };
  },
  mounted() {
    this.form = this.data.props;
    this.localData = this.data;
  },
  computed: {
    layoutImage() {
      let image = "whole.svg";

      switch (this.form.layout) {
        case ",":
          image = "halves.svg";
          this.newColumnLength = 2
          break;
        case "1-3,":
          image = "thirds.svg";
          this.newColumnLength = 3
          break;
        case "1-4,":
          image = "quarters.svg";
          this.newColumnLength = 4
          break;
        case "3-4,":
          image = "quarters-3-1.svg";
          this.newColumnLength = 2
          break;
        case ",3-4":
          image = "quarters-1-3.svg";
          this.newColumnLength = 2
          break;
        case "2-3,":
          image = "thirds-2-1.svg";
          this.newColumnLength = 2
          break;
        case ",2-3":
          image = "thirds-1-2.svg";
          this.newColumnLength = 2
          break;
      }
      return image;
    },
    newColumnLength() {
      let count = 1;

      switch (this.form.layout) {
        case ",":
          count = 2
          break;
        case "1-3,":
          count = 3
          break;
        case "1-4,":
         count = 4
          break;
        case "3-4,":
          count = 2
          break;
        case ",3-4":
          count = 2
          break;
        case "2-3,":
          count = 2
          break;
        case ",2-3":
          count = 2
          break;
      }
      return count;
    }
  },
  watch: {
    'form.layout'(val) {
      switch (val) {
        case ",":
          this.layoutLabel = 'Hälften'
          break;
        case "1-3,":
          this.layoutLabel = 'Drittel'
          break;
        case "1-4,":
         this.layoutLabel = 'Viertel'
          break;
        case "3-4,":
          this.layoutLabel = 'Viertel 3-1'
          break;
        case ",3-4":
          this.layoutLabel = 'Viertel 1-3'
          break;
        case "2-3,":
           this.layoutLabel = 'Drittel 2-1'
          break;
        case ",2-3":
          this.layoutLabel = 'Drittel 1-2'
          break;
      }
    }
  },
  methods: {
    editGrid() {
      this.$refs.selectRowModal.open();
    },
    selectGrid(grid) {
      this.form.layout = grid;
      let allElements = _.flatMap(this.localData.child, function(c) {
        return c.child;
      });

      //let arrayLength = this.localData.props.layout
      //  .split(",")[0]
      //  .replace("1-", "");

      //const newColumnLength = arrayLength === "" ? 2 : arrayLength;

      //const newColumnLength = this.localData.props.layout.split(",").length;

      const currentChilds = _.filter(this.localData.child, c => {
        return c.child && c.child.length;
      });

      const currentColumnLength = this.localData.child.length;

      console.log("newColumnLength: " + this.newColumnLength);
      console.log("currentChildLength: " + currentChilds.length);
      console.log("currentColumnLength: " + currentColumnLength);
      console.log("allElements", allElements.length);

        const newArraysChunks = _.chunk(
          allElements,
          Math.ceil(allElements.length / this.newColumnLength)
        );


        const widths = this.form.layout.split(",")
        let newWidths = []
        for(let i = 0; i < this.newColumnLength; i++) {
          newWidths[i] = typeof widths[i] === 'undefined' ? '' : widths[i]
        }


      if (allElements.length >= this.newColumnLength) {

        //this.localData.child = []
        this.localData.child = _.dropRight(
          this.localData.child,
          currentColumnLength - this.newColumnLength
        );

        for (let i = 0; i < this.newColumnLength; i++) {


          if (typeof this.localData.child[i] === "undefined") {
                this.localData.child.push(_.assign({},{
                  props: {
                    widths: [newWidths[i]]
                  }
                }, this.defaultColumnData));

              }

            this.localData.child[i].child = typeof newArraysChunks[i] === "undefined" ? [] : newArraysChunks[i];
            console.log(newWidths[i])
           this.localData.child[i].props.widths = [newWidths[i]];
        }

      }

      if (allElements.length < this.newColumnLength) {

        for (let i = 0; i < this.newColumnLength - currentColumnLength; i++) {
        this.localData.child.push(
          _.assign(
            {},
            {
              props: {
                widths: [newWidths[i]]
              }
            },
            this.defaultColumnData
          )
        );
        this.localData.child[i].child = typeof newArraysChunks[i] === "undefined" ? [] : newArraysChunks[i];
                  this.localData.child[i].props.widths = [newWidths[i]];

      }
      }

      /*
      let chunks = 1;
      if (newColumnLength > 1 && chunks > 1) {
        chunks = newColumnLength;
      }

      const newArrays = _.chunk(allElements, chunks);

      if (newColumnLength < currentColumnLength) {
        this.localData.child = _.dropRight(
          this.localData.child,
          newColumnLength
        );

        for (let i = 0; i < newColumnLength; i++) {

          this.localData.child[i].child = _.union(
            this.localData.child[i].child,
            allElements
          );
          this.localData.child[i].props.widths = [this.form.layout];
        }
      }
      if (newColumnLength > currentColumnLength) {
        for (let i = 0; i < newColumnLength; i++) {
          if (typeof this.localData.child[i] === "undefined") {
            this.localData.child.push(this.defaultColumnData);
          }

          this.localData.child[i].child =
            typeof newArrays[i] === "undefined" ? [] : newArrays[i];

          this.localData.child[i].props.widths = [
            this.form.layout.split(",")[0]
          ];
        }
      }

      if (currentColumnLength === newColumnLength) {
        for (let i = 0; i < newColumnLength; i++) {
          this.localData.child[i].props.widths = [
            this.form.layout.split(",")[0]
          ];
        }
      }
*/
      this.$refs.selectRowModal.close();
    },
    edit(column) {
      this.editColumn = true;
      this.editColumnData = column;
    }
  }
};
</script>
