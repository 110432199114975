<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Style</label>
          <select class="uk-select" v-model="form.style">
            <option value="">Ohne</option>
            <option value="default">Standart</option>
            <option value="primary">Primär</option>
            <option value="secondary">Sekundär</option>
            <option value="muted">Muted</option>
          </select>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Hintergrundbild</label>
          <input type="text" class="uk-input" placeholder="http://" v-model="form.background_image">
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Innenabstand</label>
          <select class="uk-select" v-model="form.padding">
            <option value="remove">Ohne</option>
            <option value="">Standart</option>
            <option value="xsmall">Sehr klein</option>
            <option value="small">Klein</option>
            <option value="large">Groß</option>
            <option value="xlarge">Extra groß</option>
          </select>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Text Farbe ändern</label>
          <select class="uk-select" v-model="form.text_color">
            <option value="">Keine</option>
            <option value="light">Hell</option>
            <option value="dark">Dunkel</option>
          </select>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Modal from "@/components/Modal";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.form = this.data.props;
  }
};
</script>
